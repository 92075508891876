// extracted by mini-css-extract-plugin
export var container = "producten-module--container--20eb0";
export var productCard = "producten-module--product-card--f5d12";
export var productCardAction = "producten-module--product-card-action--57087";
export var productCardButton = "producten-module--product-card-button--6af99";
export var productCardCompanyLogo = "producten-module--product-card-company-logo--2c791";
export var productCardDescription = "producten-module--product-card-description--31d93";
export var productCardImage = "producten-module--product-card-image--a0e46";
export var productCardRow = "producten-module--product-card-row--0ce48";
export var productCardText = "producten-module--product-card-text--8383c";
export var productCardTitle = "producten-module--product-card-title--dfd01";
export var productCounter = "producten-module--product-counter--947e6";
export var productPageHeader = "producten-module--product-page-header--be5ea";
export var productPageHeaderImage = "producten-module--product-page-header-image--66a88";
export var productPageHeaderText = "producten-module--product-page-header-text--0fa4d";
export var productsGrid = "producten-module--products-grid--23e6e";
export var productsMain = "producten-module--products-main--03ca3";
export var productsPage = "producten-module--products-page--5c242";